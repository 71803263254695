@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    .loader div {
        animation-duration: 0.5s;
    }

    .loader div:first-child {
        animation-delay: 0.1s;
    }

    .loader div:nth-child(2) {
        animation-delay: 0.3s;
    }

    .loader div:nth-child(3) {
        animation-delay: 0.6s;
    }
}

input {
    --tw-ring-shadow: 0 0 #000 !important;
}

.ProseMirror {
    min-height: 60px;
}

.ProseMirror:focus-visible {
    outline: none;
}

.ProseMirror ul {
    list-style-type: initial;
    padding: initial;
}

.ProseMirror ol {
    list-style-type: decimal;
    padding: initial;
}

.ProseMirror li > p {
    display: inline;
}

.ProseMirror li {
    font-size: 0.875em;
    line-height: 24px;
    position: relative;
    /* list-style: none; */
    list-style-position: inside;
    margin: 0.4em 1em 0.4em 1em;
}

.ProseMirror li:before {
    /* font-family: "Material Design Icons";
    content: "\F222"; */
}
